import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  tableContainer: {
    padding: "1% 3% 1% 3%"
  },
  cardHeader: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.header,
    color: theme.palette.text.secondary,
    ...theme.typography.button,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
  },
  headerTitle: {
    textAlign: "left",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  headerAddButton: {
    textAlign: "right",
    justifyContent: "right",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(1)
    },
  },
  centerContentColumn: {
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  noDataMessage: {
    paddingTop: theme.spacing(2),
    fontSize: "1.5rem"
  },
  tableButtons: {
    marginLeft: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      justifyContent: "right",
      flexDirection: "column",
      align: "right"
    }
  },
}));