import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { useTaxStyles } from "./TaxSection.style";
import clsx from "clsx";

const TaxSection = ({ taxes, className }) => {
  const classes = useTaxStyles();

  return (
    <div className={className}>
      {taxes?.map((tax, index) => {
        return (
          <div key={index} className={classes.taxContainer}>
            <Typography className={clsx("tax-description", classes.taxItem)}>
              {`${tax.description} ${tax.percentage}%`}
            </Typography>
            <Typography className={clsx("tax-value", classes.taxItem)}>
              {`$${tax.value.toFixed(2.0)}`}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

TaxSection.defaultProps = {
  taxes: []
};

TaxSection.propTypes = {
  taxes: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      value: PropTypes.number.isRequired,
      percentage: PropTypes.number.isRequired
    })
  )
};

export default TaxSection;
