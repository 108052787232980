import React from "react";
import PropTypes from "prop-types";
import PricingStructureSimple, {getReadablePricingTextSimple} from "./PricingStructure.simple";
import PricingStructureIterative, {getReadablePricingTextIterative} from "./PricingStructure.iterative";
import PricingStructureAdvanced, {getReadablePricingTextAdvanced} from "./PricingStructure.advanced";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { generateUUID } from "../../Rate"
import styles from "../styles";
import clsx from "clsx";

export const getReadablePricingText = function(records) {
  if(!records.length) return "";
  return PricingStructureTypes[records[0].type][1](sortPricing(records));
};

export const sortPricing = function(rec) {
  if(parseInt(rec[0].type,10)==1) {
    rec = JSON.parse(JSON.stringify(rec));
    rec.sort((a,b)=>{if(a.duration*a.durationMultiplier>b.duration*b.durationMultiplier) return -1;if(a.duration*a.durationMultiplier<b.duration*b.durationMultiplier) return 1;return 0;});
  }
  return rec;
}

const PricingStructure = ({records,onChange}) => {
  const style = styles();

  const BlankRateRecord = `{
    "key": "${generateUUID()}",
    "rateTitle": "${records.length?records[0].rateTitle:""}",
    "type": ${parseInt(records.length?records[0].type:0,10)},
    "price": 0,
    "currencyAbbreviation": "USD",
    "duration": 0,
    "durationMultiplier": 1,
    "durationName": "second",
    "maxLimit": 0,
    "carryOverflow": false
  }`;

  const editors = records.map((record, i) => {
    var deleteButton = (
      <IconButton aria-label="delete"
        key={record.key+"BUTTON"}
        onClick={()=>{records.splice(i,1);onChange()}}
        className={clsx(`${record.rateTitle}_deleteButton`, style.PriceComponentRemoveButton)}
      >
        <DeleteIcon fontSize="small"/>
      </IconButton>
    );

    var priceBox = React.createElement(PricingStructureTypes[record.type][0], { key:record.key+"PRICE", record:record, onChange:onChange});

    return (
      <div key={record.key+"CONTAINER"}>
        <div className={clsx("price_textField", style.PriceComponent)} key={record.key+"DIV"}>
          {priceBox}
          {records.length>1?deleteButton:""}
        </div>
        <br/>
      </div>
    );
  });

  function addPricePoint() {
    records.push(JSON.parse(BlankRateRecord));
    onChange();
  }

  return (
    <div>
      {getReadablePricingText(records)}
      {editors}
      <div><Button onClick={addPricePoint}>add price point</Button></div>
    </div>
  );
};



PricingStructure.defaultProps = {
  onChange: () => {},
};

const PricingStructureTypes = {
  "1": [PricingStructureSimple,getReadablePricingTextSimple],
  "2": [PricingStructureIterative,getReadablePricingTextIterative],
  "3": [PricingStructureAdvanced,getReadablePricingTextAdvanced],
};

PricingStructure.propTypes = {
  record: PropTypes.shape({
    key: PropTypes.string,
    price: PropTypes.array,
    duration: PropTypes.number,
    durationMultiplier: PropTypes.number,
    durationName: PropTypes.string,
    maxLimit: PropTypes.number,
    carryOverflow: PropTypes.bool
  }),
};

export default PricingStructure;
