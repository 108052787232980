import React, { useState, useEffect, useCallback } from "react";
import RateListView from "./RatesListView/RateListView";
import useHasPermissions from "../../hooks/useHasPermissions";
import RateService from "../../services/RateService";
import apiClient from "../../auth/apiClient";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import RatesComposer from "./RatesComposer";
import { cleanRatesForSubmit } from "./index";
import { useLocation } from "react-router-dom";
import TaxService from "../../services/TaxesService";

const rateService = new RateService(apiClient);
const taxService = new TaxService(apiClient);

const RateSetup = ({ entityID }) => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const { textConfirmation } = useConfirmationDialog();
  const location = useLocation();
  const defaultRateID = "00000000-0000-0000-0000-000000000000";

  const [editMode, setEditMode] = useState(false);
  const [rateData, setRateData] = useState([]);
  const [selectedRateID, setSelectedRateID] = useState(null);
  const [taxes, setTaxes] = useState([]);

  const { hasPermissions } = useHasPermissions();
  const AddRatePermission = hasPermissions(["rates.add"]);
  const EditRatePermission = hasPermissions(["rates.edit"]);
  const DeleteRatePermission = hasPermissions(["rates.delete"]);

  const getTaxes = useCallback(async () => {
    try {
      const resp = await taxService.getPageableTaxes(entityID);
      return (
        resp.data?.collection.map(tax => {
          return {
            taxID: tax.taxID,
            taxName: tax.taxName
          };
        }) ?? []
      );
    } catch (err) {
      console.error(err);
      return [];
    }
  }, [entityID]);

  useEffect(() => {
    getTaxes(entityID).then(taxes => {
      taxes.unshift({
        taxID: 0,
        taxName: "<none>"
      });
      setTaxes(taxes);
    });
  }, [getTaxes, entityID]);

  const getRatesForEntity = async () => {
    try {
      const result = await rateService.getRatesForEntity(entityID);
      setRateData([...result.data]);
    } catch {
      enqueueSnackbar("Failed to get rate data", {
        variant: "error"
      });
    }
  };

  const saveRate = async (rateObject, rateDisplay) => {
    var rate = JSON.parse(JSON.stringify(rateObject));

    if (rate.rateApplications == null || rate.rateApplications.length == 0) {
      rate.rateApplications = [];
    }
    if (rate.name == "") rate.name = "Unnamed Rate";
    if (rate.rateID == "") rate.rateID = defaultRateID;
    if (rate.entityID == "") rate.entityID = entityID;
    if (rate.rateSettings == null) rate.rateSettings = { lostTicketFee: 0 };
    rate.rateDisplay = rateDisplay;
    rate.taxID = rate.taxID === 0 ? undefined : rate.taxID;
    rate.rateApplications = cleanRatesForSubmit(rate.rateApplications);

    if (
      rate.rateSettings.prepaidSettings.allowEntryUpTo <
      rate.rateSettings.prepaidSettings.earlyEntryGrace
    ) {
      rate.rateSettings.prepaidSettings.allowEntryUpTo =
        rate.rateSettings.prepaidSettings.earlyEntryGrace;
    }

    let ratesArray = [...rateData];
    if (rate.rateID == defaultRateID) {
      try {
        var result = await rateService.createRateWithResult(rate);
        enqueueSnackbar("Successfully saved data", {
          variant: "success"
        });

        rate.rateID = result.data.rateID;
        rate.rateBlobID = result.data.rateBlobID;
        setRateData([...ratesArray, rate]);
        setSelectedRateID([rate.rateID]);
      } catch {
        enqueueSnackbar(`There was an issue while trying to save`, {
          variant: "error"
        });
      }
    } else {
      try {
        var result = await rateService.updateRateWithResult(rate);
        enqueueSnackbar("Successfully saved data", {
          variant: "success"
        });
        rate.rateBlobID = result.data.rateBlobID;
        setRateData([...ratesArray.filter(x => x.rateID != rate.rateID), rate]);
      } catch {
        enqueueSnackbar(`There was an issue while trying to save`, {
          variant: "error"
        });
      }
    }
  };

  const deleteRate = async rateID => {
    let rateArray = [...rateData];
    let rateObject = rateArray.find(x => x.rateID == rateID);

    let continueDelete = await textConfirmation({
      title: `Are you sure you want to delete rate ${rateObject.name}?`,
      message:
        "Please enter the rate name EXACTLY as shown in the input box below to delete.",
      textConfirmation: rateObject.name
    });

    if (!continueDelete) {
      return;
    }

    try {
      await rateService.deleteRate(rateID);
      setRateData([...rateArray.filter(x => x.rateID != rateID)]);
      toggleEditMode(false);
    } catch {
      enqueueSnackbar("Failed to delete rate", {
        variant: "error"
      });
    }
  };

  useEffect(() => {
    getRatesForEntity();
  }, [entityID]);

  useEffect(() => {
    if (location.pathname == "/rates") {
      toggleEditMode(false, null);
    }
  }, [location]);

  const toggleEditMode = (boolValue, rateID) => {
    setEditMode(boolValue);
    setSelectedRateID(rateID);
  };

  return (
    <>
      {!editMode && (
        <RateListView
          rateArray={[...rateData]}
          toggleEditMode={toggleEditMode}
          DeleteRatePermission={DeleteRatePermission}
          EditRatePermission={EditRatePermission}
          AddRatePermission={AddRatePermission}
          deleteRate={deleteRate}
        />
      )}
      {editMode && (
        <RatesComposer
          entityID={entityID}
          selectedRateID={selectedRateID}
          handleSave={saveRate}
          handleDelete={deleteRate}
          ratesArray={rateData}
          taxes={taxes}
        />
      )}
    </>
  );
};

export default RateSetup;
