import React, { useState } from "react";
import {
  Button,
  Grid,
  Zoom,
  DialogActions,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRetweetAlt, faBan } from "@fortawesome/pro-regular-svg-icons";
import clsx from "clsx";

import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import DevicesSelect from "../Dropdowns/DevicesSelect/DevicesSelect.index";
import { useStyles } from "./styles";
import { useGate } from "../../hooks/useGate";

const QuickActionsPulseDialog = ({ onClose }) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();

  const [deviceToPulse, setDeviceToPulse] = useState({ DeviceID: "" });
  const { sendGateAction } = useGate(deviceToPulse.DeviceID, true);

  const handleDeviceChange = (e) => {
    setDeviceToPulse({ ...deviceToPulse, DeviceID: e.target.value });
  };

  const handlePulseGate = () => {
    sendGateAction("vend");
    enqueueSnackbar(`Command sent to pulse the gate`, {
      variant: "info",
      TransitionComponent: Zoom,
      tag: "cycleGateSent",
    });
    onClose();
  };

  return (
    <>
      <DialogTitle className={classes.pulseTitle}>
        Cycle Gate on a Device
      </DialogTitle>
      <Divider style={{ marginBottom: 15, marginTop: -10 }} />
      <DialogActions className={clsx(classes.pulseActions)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DevicesSelect
              onSelect={handleDeviceChange}
              value={deviceToPulse.DeviceID}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container justify="flex-start">
              <Button
                startIcon={<FontAwesomeIcon icon={faRetweetAlt} />}
                className={clsx("pulse-command-button")}
                onClick={handlePulseGate}
                variant="contained"
                color="primary"
                disabled={deviceToPulse?.DeviceID === null}
              >
                Cycle Gate
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container justify="flex-end">
              <Button
                startIcon={<FontAwesomeIcon icon={faBan} />}
                className={clsx("pulse-cancel-button")}
                onClick={onClose}
                color="secondary"
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default QuickActionsPulseDialog;
