import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import DurationEntry, { getVerboseDisplayValue } from "../Inputs/DurationEntry";

export const getReadablePricingTextSimple = function(records) {
  var rv = [];
  for(var i=records.length;i-->0;) {
    rv.push(
      <div key={`pcs${i}`}>
        {`\$${records[i].price} ${records[i].duration?`for up to ${getVerboseDisplayValue(records[i].duration*records[i].durationMultiplier)}`:``}`}
      </div>
    );
  }
  return rv;
}

const PricingStructureAdvanced = ({ record, onChange }) => {
  const handleChange = (name, value) => {
    record[name] = value;
    onChange();
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    if (e.target.type === "number") value = parseFloat(parseFloat(e.target.value,10).toFixed(2),10);
    if (isNaN(value)) value = 0;
    record[e.target.name] = value;
    onChange();
  };

  return (
    <div>
      <TextField
        label="Price"
        type="number"
        inputProps={{ min: "0", step: "0.01" }}
        name="price"
        value={parseFloat(record.price)}
        onChange={handleInputChange}
        title=""
      />
      <DurationEntry
        label="Duration"
        name="duration"
        value={record.duration * record.durationMultiplier}
        onChange={handleChange}
        title=""
      />
    </div>
  );
};

PricingStructureAdvanced.displayName = "PricingStructureAdvanced";

PricingStructureAdvanced.defaultProps = {
  onChange: () => {},
};

PricingStructureAdvanced.propTypes = {
  record: PropTypes.shape({
    key: PropTypes.string,
    price: PropTypes.number,
    duration: PropTypes.number,
    durationMultiplier: PropTypes.number,
    durationName: PropTypes.string,
    maxLimit: PropTypes.number,
    carryOverflow: PropTypes.bool,
  }),
};

export default PricingStructureAdvanced;
