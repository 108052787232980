import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { useStyles } from "./styles";

export const getTranslations = () => {
  return {
    days: 'd',
    hours: 'h',
    minutes: 'm',
    seconds: 's'
  }
};

export const fixComma = (timeString) => {
  var value = timeString
      .toString()
      .trim()
      .toLowerCase()
      .replace(',', '.');
  if (value.charAt(0) === '.') {
      return "0" + value;
  }
  return value;
}

export const convertStringToEnglish = (string) => string
  .replace(getTranslations().days, 'd')
  .replace(getTranslations().hours, 'h')
  .replace(getTranslations().minutes, 'm')
  .replace(getTranslations().seconds, 's')

export const convertArrayOfUnitsToEnglish = (units) => {
  var flatten = units.toString();
  var translated = convertStringToEnglish(flatten);
  return translated.split(',');
}

export const getHMS = function(v) {
  var rv = [];
  if (!v) v = 0;
  rv.push(v % 60);
  rv.push((v = Math.floor(v / 60)) % 60);
  rv.push((v = Math.floor(v / 60)) % 24);
  rv.push((v = Math.floor(v / 24)) % 7);
  rv.push(Math.floor(v / 7));
  return rv;
};

export const getVerboseDisplayValue = function(v) {
  var hms = getHMS(v);
  var rv = "";
  rv += hms[4] ? (rv ? " " : "") + hms[4] + " week" : "";
  rv += hms[3] ? (rv ? " " : "") + hms[3] + " day" : "";
  rv += hms[2] ? (rv ? " " : "") + hms[2] + " hour" : "";
  rv += hms[1] ? (rv ? " " : "") + hms[1] + " minute" : "";
  rv += hms[0] ? (rv ? " " : "") + hms[0] + " second" : "";
  return rv;
};

export const getDisplayValue = function(v) {
  var hms = getHMS(v);
  var rv = "";
  rv += hms[4] ? (rv ? " " : "") + hms[4] + "w" : "";
  rv += hms[3] ? (rv ? " " : "") + hms[3] + "d" : "";
  rv += hms[2] ? (rv ? " " : "") + hms[2] + "h" : "";
  rv += hms[1] ? (rv ? " " : "") + hms[1] + "m" : "";
  rv += hms[0] ? (rv ? " " : "") + hms[0] + "s" : "";
  return rv;
};

export const prettifyDuration = function(seconds, timeUnits) {
  if (timeUnits === void 0) { timeUnits = [getTranslations().hours, getTranslations().minutes, getTranslations().seconds]; }
  if (Number.isNaN(seconds)) {
      throw new TypeError(seconds + " is not a number.");
  }
  var negative = seconds < 0;
  var newSeconds = Math.abs(seconds);
  var duration = [];
  var units = [
      { unit: getTranslations().days, seconds: 86400 },
      { unit: getTranslations().hours, seconds: 3600 },
      { unit: getTranslations().minutes, seconds: 60 },
      { unit: getTranslations().seconds, seconds: 1 },
  ];
  var selectedUnits = [];
  timeUnits.forEach(function (timeUnit) {
      units.forEach(function (unit) {
          if (timeUnit === unit.unit) {
              selectedUnits.push(unit);
          }
      });
  });

  selectedUnits.forEach(function (unit) {
      var value = Math.floor(newSeconds / unit.seconds);
      if (value) {
          duration.push(value + unit.unit);
      }
      newSeconds %= unit.seconds;
  });
  return (negative ? '-' : '') + duration.join(' ') ;
}

export const prettyDurationToSeconds = function (duration, defaultUnit, timeUnits) {
  if (defaultUnit === void 0) { defaultUnit = 'm'; }
  if (timeUnits === void 0) { timeUnits = ['h', 'm']; }
  if (typeof duration !== 'string' || duration === '') {
      return Number.NaN;
  }
  var newDuration = fixComma(convertStringToEnglish(duration));
  var newTimeUnits = convertArrayOfUnitsToEnglish(timeUnits);
  var unitsMap = {
      d: 86400,
      h: 3600,
      m: 60,
      s: 1,
  };
  var seconds = 0;
  var unit;
  var lastUnit;
  var getNextUnit = function (lastSelectedUnit) {
      var lastUnitIndex = newTimeUnits.indexOf(lastSelectedUnit);
      if (lastUnitIndex === -1) {
          return defaultUnit;
      }
      var nextUnitKey = newTimeUnits[lastUnitIndex + 1];
      return nextUnitKey || defaultUnit;
  };
  newDuration = newDuration.replace(/\s/g, '').replace(/([a-z])/g, '$1 ');
  newDuration.split(' ').forEach(function (value) {
      unit = value.slice(-1);
      if (!newTimeUnits.includes(unit)) {
          unit = getNextUnit(lastUnit);
      }
      seconds += Number(value.replace(unit, '')) * unitsMap[unit];
      lastUnit = unit;
  });
  return Math.round(seconds);
};

const DurationEntry = ({ name, value, label, title, onChange, disabled, variant}) => {
  const [duration, setDuration] = useState(value);
  const [displayValue, setDisplayValue] = useState(prettifyDuration(duration));
  const KEY_RETURN = 13;
  const classes = useStyles();
  const thisClassName = eval('classes.' + label.replace(/\s/g, "") + '_textField');
  const tooltip = title || "Enter the time by adding an h for hours or an m for minutes to the value being entered. Example: 2h30m";

  const onFieldChange = event => {
    const {value} = event.target;
    setDisplayValue(value);
  };

  const onKeyDown = event => {
    if (event.keyCode === KEY_RETURN) {
        onBlur();
        if (event.ctrlKey || event.metaKey) {
            // We need to stall the submit to make sure dependant fields have been updated
            const {nativeEvent, target} = event;
            event.stopPropagation();
            setTimeout(() => {
                target.form.dispatchEvent(nativeEvent);
            }, 0);
        }
    }
  };

  function onBlur() {
    let seconds = displayValue != '' 
        ? prettyDurationToSeconds(displayValue)
        : undefined;

    if (!isNaN(seconds)) {
      setDisplayValue(prettifyDuration(seconds));
    } else {
      seconds = 0;
      setDisplayValue('');
    }
    setDuration(seconds);
    onChange(name, seconds); // ( this updates the preview window )
  }

  useEffect(() => {
    setDisplayValue(prettifyDuration(value));
  }, [value]);

  return (
      <TextField
        label={label}
        type="text"
        value={displayValue}
        title={tooltip}
        onChange={onFieldChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        className={clsx(thisClassName)}
        InputProps={{
          readOnly: Boolean(disabled),
          "aria-readonly": Boolean(disabled),
          disabled: Boolean(disabled),
          placeholder: "0h 00m", 
          name: name
        }}
        variant={variant}
      />
  );
};

DurationEntry.defaultProps = {
  onChange: () => {},
  disabled: false,
};

DurationEntry.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  title: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
};

export default DurationEntry;
