import React, { useState } from "react";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import DurationEntry, { getVerboseDisplayValue } from "./DurationEntry";
import styles from "../styles";
import clsx from "clsx";
import MomentUtils from '@date-io/moment';

export const getTimeFromCronex = function(cronex) {
  if (cronex.startsWith("relative:")) return null;
  var c = cronex.split(" ");
  return c[1] + ":" + padDecimal(c[0], 2);
};

export const getReadableCadenceText = function(cronex) {
  if (cronex.startsWith("relative:")) return getRelativeCadenceText(cronex);
  var c = cronex.split(" ");
  var vtime =
    (c[1] == 0) & (c[0] == 0) ? "Midnight" : c[1] + ":" + padDecimal(c[0], 2);
  var vday = daySelectorDisplayValue(c[2]);
  var vmonth = monthSelectorDisplayValue(c[3]);
  var vdow = dowSelectorDisplayValue(c[4]);
  var rv = "Starts at " + vtime;
  if (c[2] != "*") rv += " on " + vday;
  if (c[3] != "*") rv += " in " + vmonth;
  rv += " on " + vdow;
  return rv;
};

export const getRelativeCadenceText = function(relex) {
  var parts = relex.split("relative:")[1].split(" ");
  var offset = getRelativeOffset(relex);
  var repeat = getRelativeRepeat(relex);
  var startText = `Starts ${
    !offset ? "immediately" : getVerboseDisplayValue(offset)
  } after entry.`;
  var repeatText = repeat
    ? ` Repeats every ${getVerboseDisplayValue(repeat)}.`
    : "";
  return `${startText} ${repeatText}`;
};

const getRelativeOffset = function(rel) {
  return parseInt(rel.split("relative:")[1].split(" ")[0], 10);
};

const getRelativeRepeat = function(rel) {
  return parseInt(rel.split("relative:")[1].split(" ")[1], 10);
};

export const dowSelectorDisplayValue = function(ival) {
  var ival = Array.isArray(ival) ? ival : ival.split(",");
  var daystring = Array.isArray(ival) ? ival.join(",") : ival;
  switch (daystring) {
    case "*":
    case alldowstring:
      return "Every Day of the Week";
    case "1,2,3,4,5":
      return "Weekdays Only";
    case "0,6":
      return "Weekends Only";
  }
  var rv = [];
  alldows.map((name, i) => {
    if (ival.indexOf(i + "") >= 0) rv.push(name);
  });
  return rv.join(", ");
};

export const monthSelectorDisplayValue = function(ival) {
  var ival = Array.isArray(ival) ? ival : ival.split(",");
  var monthstring = Array.isArray(ival) ? ival.join(",") : ival;
  switch (monthstring) {
    case "*":
    case allmonthstring:
      return "Every Month";
  }
  var rv = [];
  allmonths.map((name, i) => {
    if (ival.indexOf(i + 1 + "") != -1) rv.push(name);
  });
  return rv.join(", ");
};

export const daySelectorDisplayValue = function(ival) {
  var ival = Array.isArray(ival) ? ival : ival.split(",");
  var daystring = Array.isArray(ival) ? ival.join(",") : ival;
  switch (daystring) {
    case "*":
    case alldaystring:
      return "Every Day of the Month";
  }
  var rv = [];
  alldays.map((name, i) => {
    if (ival.indexOf(i + 1 + "") != -1) rv.push(name);
  });
  return rv.join(", ");
};

export const sort = function(arr) {
  arr.sort(
    new Intl.Collator(undefined, { numeric: true, sensitivity: "base" }).compare
  );
};

export const padDecimal = function(d, n) {
  for (d = parseInt(d, 10) + ""; d.length < n; d = "0" + d);
  return d;
};

const alldows = "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday".split(
  ","
);
const allmonths = "January,February,March,April,May,June,July,August,September,October,November,December".split(
  ","
);
const alldays = "1st,2nd,3rd,4th,5th,6th,7th,8th,9th,10th,11th,12th,13th,14th,15th,16th,17th,18th,19th,20th,21st,22nd,23rd,24th,25th,26th,27th,28th,29th,30th,31st".split(
  ","
);
const allmonthstring = "1,2,3,4,5,6,7,8,9,10,11,12";
const alldaystring =
  "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31";
const alldowstring = "0,1,2,3,4,5,6";

const MINUTE = 0;
const HOUR = 1;
const DAY = 2;
const MONTH = 3;
const WEEKDAY = 4;

const CadenceSelector = ({ name, cadence, onChange, disabled }) => {
  const style = styles();
  const [isExpanded, setExpanded] = useState(true);

  function cadencePart(n) {
    var pieces = cadence.split(" ");
    if (pieces.length > n) return pieces[n];
    if (n < 2) return "0";
    return "*";
  }

  function getCadenceTime() {
    return new Date(
      `1900-01-01T${padDecimal(cadencePart(1) || 0, 2)}:${padDecimal(
        cadencePart(0) || 0,
        2
      )}:00`
    );
  }

  function getCadenceDay() {
    return cadencePart(2).split(",");
  }

  function getCadenceMonth() {
    return cadencePart(3).split(",");
  }

  function getCadenceWeekday() {
    return cadencePart(4).split(",");
  }

  function changeRelative(subelementname, value) {
    var offset = getRelativeOffset(cadence);
    var repeat = getRelativeRepeat(cadence);
    switch (subelementname) {
      case "relativeOffset":
        offset = value;
        break;
      case "relativeRepeat":
        repeat = value;
        break;
    }
    cadence = `relative:${offset} ${repeat}`;
    onChange(name, cadence);
    return cadence;
  }

  function updateCadence(changes) {
    if (!Array.isArray(changes)) changes = [changes];
    var tempcadence = cadence.split(" ");
    for (var i = 0; i < changes.length; i++) {
      tempcadence[changes[i].position] = changes[i].value;
    }
    cadence = tempcadence.join(" ");
    onChange(name, cadence);
    return tempcadence;
  }

  function updateStartTime(e) {
    if (e == null) return null;
    if (isNaN(e.minute()) || isNaN(e.hour())) return null;
    updateCadence([
      { position: MINUTE, value: e.minute() },
      { position: HOUR, value: e.hour() },
    ]);
  }

  function updateMonth(e) {
    var newval = e.target.value;
    for (var p; (p = newval.indexOf("*")) != -1; newval.splice(p, 1));
    if (!newval.length) newval.push("*");
    sort(newval);
    if (newval.join(",") == allmonthstring) newval = ["*"];
    updateCadence({ position: MONTH, value: newval });
  }

  function updateDay(e) {
    var newval = e.target.value;
    for (var p; (p = newval.indexOf("*")) != -1; newval.splice(p, 1));
    if (!newval.length) newval.push("*");
    sort(newval);
    if (newval.join(",") == alldaystring) newval = ["*"];
    updateCadence({ position: DAY, value: newval });
  }

  function updateWeekday(e) {
    var newval = e.target.value;
    for (var p; (p = newval.indexOf("*")) != -1; newval.splice(p, 1));
    if (!newval.length) newval.push("*");
    sort(newval);
    if (newval.join(",") == alldowstring) newval = ["*"];
    updateCadence({ position: WEEKDAY, value: newval });
  }

  function getAbsoluteEditor() {
    return (
      <div className={style.CadenceBlock}>
        <div style={{ display: isExpanded ? "block" : "none" }}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
          <TimePicker
            disabled={disabled}
            margin="normal"
            label="Rate Start Time"
            className={clsx("rateStartTimePicker")}
            value={getCadenceTime()}
            onChange={updateStartTime}
          />
          </MuiPickersUtilsProvider>
          <InputLabel shrink>Rate Applicable Months</InputLabel>
          <Select
            disabled={disabled}
            multiple
            name="month"
            className={clsx("monthSelectDropdown")}
            value={getCadenceMonth()}
            onChange={updateMonth}
            input={<Input />}
            renderValue={monthSelectorDisplayValue}
          >
            {allmonths.map((name, i) => (
              <MenuItem key={i} value={i + 1 + ""}>
                <Checkbox
                  className={`${name}_checkbox`}
                  checked={
                    getCadenceMonth().indexOf("*") != -1 ||
                    getCadenceMonth().indexOf(i + 1 + "") != -1
                  }
                />
                <ListItemText primary={name} className={clsx(`${name}_selection`)} />
              </MenuItem>
            ))}
          </Select>
          <InputLabel shrink>Rate Applicable Days of the Month</InputLabel>
          <Select
            disabled={disabled}
            multiple
            name="day"
            value={getCadenceDay()}
            className={"daySelectDropdown"}
            onChange={updateDay}
            input={<Input />}
            renderValue={daySelectorDisplayValue}
          >
            {alldays.map((name, i) => (
              <MenuItem key={i} value={i + 1 + ""}>
                <Checkbox
                  className={`${name}_checkbox`}
                  checked={
                    getCadenceDay().indexOf("*") != -1 ||
                    getCadenceDay().indexOf(i + 1 + "") != -1
                  }
                />
                <ListItemText primary={name} className={clsx(`${name}_selection`)} />
              </MenuItem>
            ))}
          </Select>
          <InputLabel shrink>Rate Applicable Days of the Week</InputLabel>
          <Select
            disabled={disabled}
            multiple
            name="weekday"
            className={clsx("weekdaySelectDropdown")}
            value={getCadenceWeekday()}
            onChange={updateWeekday}
            input={<Input />}
            renderValue={dowSelectorDisplayValue}
          >
            {alldows.map((name, i) => (
              <MenuItem key={i} value={i + ""}>
                <Checkbox
                  className={`${name}_checkbox`}
                  checked={
                    getCadenceWeekday().indexOf("*") != -1 ||
                    getCadenceWeekday().indexOf(i + "") != -1
                  }
                />
                <ListItemText primary={name} className={clsx(`${name}_selection`)} />
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    );
  }

  function getRelativeEditor() {
    return (
      <div className={style.CadenceBlock}>
        <div style={{ display: isExpanded ? "block" : "none" }}>
          <DurationEntry
            label="Offset"
            name="relativeOffset"
            value={getRelativeOffset(cadence)}
            onChange={changeRelative}
            title=""
          />
          <DurationEntry
            label="Repeat"
            name="relativeRepeat"
            value={getRelativeRepeat(cadence)}
            onChange={changeRelative}
            title=""
          />
        </div>
      </div>
    );
  }

  return cadence.startsWith("relative:")
    ? getRelativeEditor()
    : getAbsoluteEditor();
};

CadenceSelector.displayName = "CadenceSelector";

CadenceSelector.defaultProps = {
  onChange: () => {},
  disabled: false,
};

export default CadenceSelector;
