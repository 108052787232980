import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import RateComponent from "./RateComponent";
import RateWizard from "./Wizard/RateWizard";
import RateAppPreview from "../../components/RateAppPreview";
import RateDisplayPreview from "../../components/RateDisplayPreview";
import useRateContext from "../../hooks/useRateContext";
import useThemeContext from "../../hooks/useThemeSwitch";
import { sortPricing } from "./Pricing/PricingStructure";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";
import moment from "moment";
import styles from "./styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import useHasPermissions from "../../hooks/useHasPermissions";
import RateService from "../../services/RateService";
import apiClient from "../../auth/apiClient";

import clsx from "clsx";

const defaultRateID = "00000000-0000-0000-0000-000000000000";
const rateService = new RateService(apiClient);

export const generateUUID = function() {
  var d = new Date().getTime();
  var d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

function addKeysForReact(o) {
  for (var i = 0; i < o.length; i++) {
    if (o[i].rateRecords == null) o[i].rateRecords = [];
    for (var k = 0; k < o[i].rateRecords.length; k++) {
      if (o[i].rateRecords[k].rateTitle == null)
        o[i].rateRecords[k].rateTitle = o[i].rateTitle;
      if (o[i].rateRecords[k].hasOwnProperty("key")) continue;
      o[i].rateRecords[k].key = generateUUID();
    }
    if (o[i].rateRecords.length) o[i].rateTitle = o[i].rateRecords[0].rateTitle;
    if (o[i].hasOwnProperty("key")) continue;
    o[i].key = generateUUID();
  }
  return o;
}

export const cleanRatesForSubmit = function(rateApps) {
  var rates = JSON.parse(JSON.stringify(rateApps));
  for (var i = 0; i < rates.length; i++) {
    rates[i].rateRecords = sortPricing(rates[i].rateRecords);
    for (var j = 0; j < rates[i].rateRecords.length; j++) {
      rates[i].rateRecords[j].price = rates[i].rateRecords[j].price || 0;
    }
  }
  return rates;
};

export const Rate = ({
  rateStructure,
  onChange,
  rateID,
  onDisplayChange,
  rateDisplay,
  tax
}) => {
  const style = styles();
  const rateComponents = addKeysForReact(rateStructure || []);
  const [, setRateComponents] = useState(rateComponents);
  const [rateLineItems, setRateLineItems] = useState({});
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const enqueueSnackbar = useEnqueueSnackbar();
  const [previewOptions, setPreviewOptions] = useState({
    enter: moment(Date.now()).format("YYYY-MM-DDTHH:mm:00"),
    exit: moment(Date.now() + 1800000).format("YYYY-MM-DDTHH:mm:00")
  });
  const { hasPermissions } = useHasPermissions();
  const DeleteRatePermission = hasPermissions(["rates.delete"]);
  const EditRatePermission = hasPermissions(["rates.edit"]);
  const AddRatePermission = hasPermissions(["rates.add"]);
  const ModifyRatePermission =
    rateID !== defaultRateID ? EditRatePermission : AddRatePermission;

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  useEffect(() => {
    updateRatePreview(
      rateComponents,
      previewOptions.enter,
      previewOptions.exit,
      tax
    );
  }, [tax]);

  const addRateComponent = newRateComponent => {
    handleRateFormChange(newRateComponent);
  };

  const removeRateComponent = i => {
    rateComponents.splice(i, 1);
    updateState();
  };

  const handleRateFormChange = useCallback(rateApp => {
    const foundIndex = rateComponents.findIndex(x => x.key === rateApp.key);
    if (foundIndex >= 0) {
      rateComponents[foundIndex] = rateApp;
    } else {
      rateComponents.push(rateApp);
    }

    updateState();
  });

  const changePreviewOptions = function(newPreviewOptions) {
    setPreviewOptions(JSON.parse(JSON.stringify(previewOptions)));
    updateRatePreview(
      rateComponents,
      previewOptions.enter,
      previewOptions.exit,
      tax
    );
  };

  const updateRatePreview = (rateApps, enterTime, exitTime, tax) => {
    var rateApps = cleanRatesForSubmit(rateApps);
    let response;

    if (exitTime < enterTime) return;

    response = rateService
      .apply({ Rates: rateApps, TaxID: tax }, enterTime, exitTime)
      .then(response => {
        processRateLineItemsGeneratorResponse(response);
      })
      .catch(() => {
        enqueueSnackbar("Failed to update rate preview", {
          variant: "error",
          tag: "FailedToUpdateRatePreview"
        });
      });
  };

  function processRateLineItemsGeneratorResponse(response) {
    if (response?.status === 200) {
      setRateLineItems(response.data);
    } else {
      enqueueSnackbar("Failed to update rate preview", {
        variant: "error",
        tag: "FailedToUpdateRatePreview"
      });
    }
  }

  const updateState = () => {
    addKeysForReact(rateComponents);
    onChange(rateComponents);
    setRateComponents([...rateComponents]);
    updateRatePreview(
      rateComponents,
      previewOptions.enter,
      previewOptions.exit,
      tax
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} lg={6}>
        {rateComponents.map((rate, index) => {
          return (
            <div
              key={`${rate.key}-DIV`}
              className={clsx(style.RateComponent, "rate-comp")}
              data-id={rate.rateTitle.replace(/\s/g, "")}
            >
              {rateID === defaultRateID || DeleteRatePermission ? (
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    removeRateComponent(index);
                  }}
                  key={`${rate.key}-BUTTON}`}
                  className={clsx(
                    `${rate.rateTitle.replace(/\s/g, "")}_deleteButton`,
                    style.RateComponentRemoveButton
                  )}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              ) : (
                <></>
              )}
              <RateComponent
                className={clsx(`rate-comp-${rate.rateTitle}`)}
                key={`123RATE${rate.key}`}
                rate={rate}
                rateString={JSON.stringify(rate)}
                onChange={updateState}
                disabled={rateID !== defaultRateID && !ModifyRatePermission}
              />
            </div>
          );
        })}
        {ModifyRatePermission && (
          <div className={style.RateComponent}>
            <RateWizard onAdd={addRateComponent} />
          </div>
        )}
      </Grid>

      <Grid item xs={6} lg={6}>
        <RateAppPreview
          preview={rateLineItems}
          onChange={changePreviewOptions}
          options={previewOptions}
        />
        <div className={style.Spacer}></div>
        <RateDisplayPreview
          content={rateDisplay ?? ""}
          onChange={onDisplayChange}
          disabled={!ModifyRatePermission}
        />
      </Grid>
    </Grid>
  );
};

Rate.defaultProps = {
  setAlert: () => {},
  onChange: () => {},
  onDisplayChange: () => {}
};

Rate.propTypes = {
  setAlert: PropTypes.func,
  onChange: PropTypes.func,
  rateID: PropTypes.string,
  onDisplayChange: PropTypes.func,
  rateDisplay: PropTypes.string,
  selectedTax: PropTypes.number
};

export default Rate;
