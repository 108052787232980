import React from "react";
import {Typography} from "@material-ui/core";

const DeleteDisabledToolTip = ({rate}) => {
  const hasAssignedEntities = rate.assignedEntities?.length > 0;
  const hasAssignedOffers = rate.assignedOffers?.length > 0;
  const mode = hasAssignedEntities && hasAssignedOffers ? "Both" : (hasAssignedEntities ? "Entities" : (hasAssignedOffers ? "Offers" : "Nothing"));
  return <>
    {mode === "Both" &&
      <>
        <Typography>Rate is in use</Typography>
        <div style={{marginTop: "1rem"}}></div>
        <Typography>Devices:</Typography>
        {rate.assignedEntities.map((name, index) => {
          return <Typography key={index}>{name}</Typography>
        })}
        <div style={{marginTop: "1rem"}}></div>
        <Typography>POE Offers:</Typography>
        {rate.assignedOffers.map((name, index) => {
          return <Typography key={index}>{name}</Typography>
        })}
      </>
    }
    {mode === "Entities" &&
      <>
        <Typography>Rate is assigned to devices:</Typography>
        {rate.assignedEntities.map((name, index) => {
          return <Typography key={index}>{name}</Typography>
        })}
      </>
    }
    {mode === "Offers" &&
      <>
        <Typography>Rate is assigned to POE Offers:</Typography>
        {rate.assignedOffers.map((name, index) => {
          return <Typography key={index}>{name}</Typography>
        })}
      </>
    }
  </>
}

export default DeleteDisabledToolTip