import {makeStyles} from "@material-ui/core/styles";

const composerStyle = makeStyles(theme => ({
  topRoot: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%"
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "15px",
    flexWrap: "wrap",
    maxWidth: "100%",
    justifyContent: "stretch",
    [theme.breakpoints.down("sm")]: {maxWidth: "400px"}
  },
  taxDropdown: {
    minWidth: "200px",
    height: "100%"
  },
  rateName: {
    minWidth: "200px",
    height: "100%"
  },
  rateDescription: {
    minWidth: "200px",
    height: "100%"
  },
  lostFee: {
    minWidth: "200px",
    height: "100%"
  },
  rateStartModeDropdown: {
    minWidth: "200px",
  },
  repaymentDropdown: {
    minWidth: "200px",
    height: "100%"
  },
  actionButton: {
    marginRight: theme.spacing(1),
  }
}));

export default composerStyle;
