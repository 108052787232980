import React, { useState, useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import DurationEntry from "../Inputs/DurationEntry";
import TextField from "@material-ui/core/TextField";
import PricingStructure, { getReadablePricingText } from "../Pricing/PricingStructure";
import CadenceSelector, { getReadableCadenceText } from "../Inputs/CadenceSelector";
import { generateUUID } from "../"
import styles from "../styles";

const RateWizardMaxRate = ({ onChange }) => {

  const initialState = `{
    "start":"relative",
    "absoluteCadence":"0 0 * * *",
    "absoluteDuration":86400,
    "relativeDuration":604800,
    "price":0,
    "rateTitle":"Generic Rate",
    "pricingStructure":[{
      "key":"${generateUUID()}",
      "rateTitle": "",
      "type": 1,
      "price": 0,
      "currencyAbbreviation": "USD",
      "duration": 0,
      "durationMultiplier": 1,
      "durationName": "second",
      "maxLimit": 0,
      "carryOverflow": false
    }]
  }`;

  const [state, setState] = useState(JSON.parse(initialState));

  function changeState(name,value) {
    state[name] = value;
    setState({...state});
    updateRateApplication(state);
  }

  function change(event) {
    changeState(event.target.name,event.target.value);
  }

  function updateInitial() {
    updateRateApplication();
  }

  function updateRateApplication(st) {
    if(!st) st=state;
    for(var i=0;i<st.pricingStructure.length;i++) st.pricingStructure[i].rateTitle = st.rateTitle;
    var rateApplicationString = `{
      "cadence": "${st.absoluteCadence}",
      "priority": 1,
      "entryOnly": false,
      "entryDuration": ${st.absoluteDuration},
      "exitStart": 0,
      "exitEnd": 0,
      "applyPartially": false,
      "forcePrice": true,
      "overlaidRate": false,
      "concatenatedRate": false,
      "rateRecords": ${JSON.stringify(st.pricingStructure)}
    }`;
    onChange(JSON.parse(rateApplicationString));
    return st;
  }


  const style = styles();
  const returnValue = [];
  returnValue.push(
    <div className={style.RateComponentSection} key="rcs1">
      <div className={style.RateComponentHeaderTitle}>Rate Component with an Absolute Start Time</div>
    </div>
  );

  useEffect(updateInitial,[]);

  return returnValue;
};

RateWizardMaxRate.defaultProps = {
  onChange:()=>{}
};

export default RateWizardMaxRate;

