import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: "470px",
    overflowY: "auto",
    position: "sticky",
    position: "-webkit-sticky",
    paddingBottom: "6px"
  },
  title: {
    textAlign: "center",
    marginTop: "10px"
  },
  lineContent: {},
  row: {
    "& > *": {
      borderBottom: "unset"
    }
  },
  arrowCell: {
    margin: "0"
  },
  startTimePicker: {
    marginLeft: theme.spacing(2)
  },
  overlapWarning: {
    margin: theme.spacing(2),
    color: "red"
  },
  rowSelector: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: "80px"
  },
  ratePreviewSection: {
    padding: "5px 20px"
  },
  rateSectionLabel: {
    borderTop: "1px solid rgba(128,128,128,0.25)",
    clear: "both",
    fontSize: theme.typography.h6.fontSize
  },
  rateSectionDetail: {
    textAlign: "right",
    clear: "both"
  },
  rateSectionDetailLabel: {
    display: "inline-block",
    float: "left"
  },
  rateSectionDetailData: {
    display: "inline-block",
    float: "right",
    paddingLeft: "3px"
  },
  rateItem: {
    borderTop: "1px solid rgba(128,128,128,0.10)",
    clear: "both"
  },
  rateItemDuration: {
    display: "inline-block",
    float: "left"
  },
  rateItemPrice: {
    display: "inline-block",
    float: "right",
    paddingLeft: "3px"
  },
  parkingSubTotal: {
    clear: "both"
  },
  parkingSubTotalLabel: {
    fontSize: theme.typography.h6.fontSize,
    float: "left"
  },
  subTotalPrice: {
    fontSize: theme.typography.h6.fontSize,
    float: "right",
    paddingLeft: "3px"
  },
  taxTotal: {
    clear: "both",
    borderTop: "1px solid rgba(128,128,128,0.10)"
  },
  taxTotalLabel: {
    fontSize: theme.typography.h6.fontSize,
    float: "left"
  },
  taxTotalPrice: {
    fontSize: theme.typography.h6.fontSize,
    float: "right",
    paddingLeft: "3px"
  },
  rateTotal: {
    clear: "both"
  },
  rateTotalLabel: {
    fontSize: theme.typography.h6.fontSize,
    float: "left"
  },
  rateTotalPrice: {
    fontSize: theme.typography.h6.fontSize,
    float: "right",
    paddingLeft: "3px"
  },
  previewStyleSelectRadio: {
    padding: "0px 20px 0px 0px",
    cursor: "pointer"
  }
}));
