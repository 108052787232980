import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectError: {
    color: "#f44336",
    marginLeft: "10px",
    fontSize: ".85em",
    marginTop: "5px",
  },
}));

export default styles;