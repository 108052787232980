import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    textTransform: "none",
    color: "inherit",
    marginRight: "20px",
    [theme.breakpoints.down("md")]: {
      marginRight: "0px"
    },
    "&& svg": {
      [theme.breakpoints.down("md")]: {
        fontSize: "30px"
      }
    }
  },
  btnContainer: {
    display: "inline-flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    "& button": { marginRight: theme.spacing(3) },
  },
  pulseActions: {
    overflow: 'hidden',
    padding: "22px"
  },
  pulseTitle:{
    color:theme.palette.primary.main,
  },
  pulseDefault: {
    borderBottom:"1px solid silver",
    color: theme.palette.primary.dark,
  },
  reversingLanesGrid: {
    width: "100%",
    margin: theme.spacing(1)
  },
  reversingLanesBox: {
    padding: '10px',
    height: "calc(100vh - 100px)",
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  listTitle: {
    fontSize : "1rem",
    textAlign: "left",
  },
  reversingLanesLine: {
    marginTop: theme.spacing(1),
    verticalAlign: "middle",
  },
  reversingLanesText: {
    verticalAlign: "middle",
    marginLeft: theme.spacing(1)
  },
  reversingLanesDrawer: {
    display: "inline-block",
    [theme.breakpoints.up('sm')]: {
      width: "500px",
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexShrink: 0,
    },
  },
  drawerPaper: {
    [theme.breakpoints.up('sm')]: {
      width: "500px",
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexShrink: 0,
    },
  },
  headerContainerSpacing: {
    marginTop: '15px'
  },
  switchBtn: {
    right: '10px',
    margin: theme.spacing(1)
  },
  cancelBtn: {
    margin: theme.spacing(2),
    float: "right",
    right: '10px',
  },
  facilityDivider: {
    height: "2.75em",
    fontWeight: "bold",
    color: theme.palette.grey[500],
    marginTop: theme.spacing(1)
  },
  facilityDividerSpacing: {
    marginTop: "0.5em"
  },
  facilityDevice: {
    paddingLeft: "2em"
  },
}));
