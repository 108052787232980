import React from "react";
import PropTypes from "prop-types";
import { Card } from "@material-ui/core";
import { useStyles } from "./styles";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import { getVerboseDisplayValue } from "../Rate/Inputs/DurationEntry";
import clsx from "clsx";
import TaxSection from "./TaxSection";

const DisplayFormat = "MM/DD/YYYY  h:mm a";
const DisplayMask = "__/__/____  __:__ _M";
const InternalFormat = "YYYY-MM-DDTHH:mm:00";
const currency = "$";
var keyinc = 0;

function getTotalPrice(rateReceipt) {
  var totalCost = 0;
  for (var i = 0; i < rateReceipt.length; i++) {
    totalCost += rateReceipt[i].totalCost;
  }
  return totalCost;
}

function parseData({ rateReceipts }, classes) {
  var rv = [];
  for (var i = 0; i < rateReceipts.length; i++) {
    rv.push.apply(rv, parseRateItemized(rateReceipts[i], classes));
  }
  return rv;
}

function parseRateItemized(rateReceipt, classes) {
  var rv = [];
  rv.push([
    <div key={keyinc++} className={classes.rateSectionLabel}>
      {rateReceipt.title}
    </div>,
    rateSectionDetail(
      "Rate Started",
      moment(z(rateReceipt.entryTime)).format("MMMM Do, h:mm a"),
      classes
    ),
    rateSectionDetail(
      "Rate Ended",
      moment(z(rateReceipt.exitTime)).format("MMMM Do, h:mm a"),
      classes
    ),
    rateSectionDetail(
      "Actual Applied Duration",
      getVerboseDisplayValue(rateReceipt.actualDuration),
      classes
    )
  ]);
  rv.push.apply(rv, getItemized(rateReceipt.itemized, classes));
  return rv;
}

/* remove Z */
function z(s) {
  return s.replace("Z", "");
}

function getItemized(itemized, classes) {
  var rv = [];
  var duration = null;
  var price = null;
  var count = 0;
  for (var i = 0; i < itemized.length; i++) {
    if (duration == null && price == null) {
      duration = itemized[i].durationSeconds;
      price = itemized[i].price;
      count = 1;
    } else if (
      duration == itemized[i].durationSeconds &&
      price == itemized[i].price
    ) {
      count++;
    } else {
      rv.push(itemizedSectionDetail(duration, price, count, classes));
      duration = itemized[i].durationSeconds;
      price = itemized[i].price;
      count = 1;
    }
  }
  if (duration != null || price != null) {
    rv.push(itemizedSectionDetail(duration, price, count, classes));
  }
  return rv;
}

function itemizedSectionDetail(duration, price, count, classes) {
  return (
    <div key={keyinc++} className={classes.rateItem}>
      <div key={keyinc++} className={classes.rateItemDuration}>{`${
        count > 1 ? count + "×" : ""
      } ${getVerboseDisplayValue(duration)}`}</div>
      <div> </div>
      <div key={keyinc++} className={classes.rateItemPrice}>{`${
        count > 1 ? "(" + count + "× " + getCurrency(price) + ")" : ""
      } ${getCurrency(price * count)}`}</div>
    </div>
  );
}

function getCurrency(value) {
  return `${currency}${value.toFixed(2)}`;
}

function rateSectionDetail(label, data, classes) {
  return (
    <div key={keyinc++} className={classes.rateSectionDetail}>
      <div key={keyinc++} className={classes.rateSectionDetailLabel}>
        {label}
      </div>
      <div> </div>
      <div key={keyinc++} className={classes.rateSectionDetailData}>
        {data}
      </div>
    </div>
  );
}

/* given enter/exit datetime, return 2 if enter date is greater, return 1 only enter time is greater, 0 otherwise */
export const dateGreaterThanOther = function(enter, exit) {
  if (
    moment(exit)
      .startOf("day")
      .diff(moment(enter).startOf("day")) < 0
  )
    return 2;
  if (moment(exit).diff(moment(enter)) < 0) return 1;
  return 0;
};

const RateAppPreview = ({ preview, onChange, options }) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();

  var renderedPreview = [];
  var newValue = { enter: options.enter, exit: options.exit };

  if (preview.taxTotals != null) {
    renderedPreview.push(
      <div key={keyinc++}>
        <div className={classes.parkingSubTotal}>
          <div className={classes.parkingSubTotalLabel}>
            Parking Price Sub Total
          </div>
          <div className={classes.subTotalPrice}>
            {getCurrency(preview.totalPreTax)}
          </div>
        </div>
      </div>
    );
    renderedPreview.push(
      <div key={keyinc++}>
        <div className={classes.taxTotal}>
          <div className={classes.taxTotalLabel}>Taxes</div>
          <div className={classes.taxTotalPrice}>
            {getCurrency(preview.taxTotals.total)}
          </div>
        </div>
        <TaxSection
          className={clsx(classes.rateItem)}
          taxes={preview.taxTotals.taxesApplied ?? []}
        />
      </div>
    );
  }

  if (preview.rateReceipts != null) {
    renderedPreview.push(
      <div key={keyinc++} className={classes.rateTotal}>
        <div key={keyinc++} className={classes.rateTotalLabel}>
          Total Price
        </div>
        <div> </div>
        <div
          key={keyinc++}
          className={clsx("rate-total-price", classes.rateTotalPrice)}
        >
          {getCurrency(preview.totalToPay)}
        </div>
      </div>
    );
    renderedPreview.push.apply(
      renderedPreview,
      parseData(
        {
          rateReceipts: preview.rateReceipts
        },
        classes
      )
    );
  }

  if (preview.overlapWarning != null)
    enqueueSnackbar(
      `Overlap detected between "${preview.overlapWarning.rateOne}" and "${preview.overlapWarning.rateTwo}"`,
      { variant: "error", tag: "ErrorOverlapDetected" }
    );

  /*
  calculatedRateCost: 0
  entryTime: "2020-12-04T12:56:00"
  exitTime: "2020-12-04T13:26:00"
  maximumLagTime: 0
  rateBlobID: "00000000-0000-0000-0000-000000000000"
  rateReceipts: [{…}]
  taxTotals: null
  totalPreTax: 0
  totalToPay: 0
  updatedRateCost: 0
  validationTotals: null
  */

  return (
    <Card className={classes.root}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DateTimePicker
          className={clsx("startTimePicker", classes.startTimePicker)}
          onClose={() => {
            var datecomp;
            if (
              (datecomp = dateGreaterThanOther(newValue.enter, options.exit)) !=
              0
            ) {
              if (datecomp == 2)
                enqueueSnackbar(
                  `The enter date you entered occurs after the exit date.`,
                  { variant: "error", tag: "ErrorInEnterDate" }
                );
              if (datecomp == 1)
                enqueueSnackbar(
                  `The enter time you entered occurs after the exit time.`,
                  { variant: "error", tag: "ErrorinEnterTime" }
                );
            }
            options.enter = newValue.enter;
            options.rows = 1;
            options.interval = Math.floor(
              moment
                .duration(
                  moment(options.exit).diff(
                    moment(options.enter).format(InternalFormat)
                  )
                )
                .asMinutes()
            );
            onChange(preview);
          }}
          onChange={val => {
            if (val == null) return;
            if (val.format(InternalFormat) == "Invalid date") return;
            newValue.enter = moment(val).format(InternalFormat);
          }}
          value={options.enter}
          inputVariant="outlined"
          margin="normal"
          label="Enter Date / Time"
          format={DisplayFormat}
          maxDate={options.exit}
          autoOk
          mask={DisplayMask}
          variant="inline"
        />
      </MuiPickersUtilsProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DateTimePicker
          className={clsx("exitTimePicker", classes.startTimePicker)}
          onClose={() => {
            var datecomp;
            if (
              (datecomp = dateGreaterThanOther(options.enter, newValue.exit)) !=
              0
            ) {
              if (datecomp == 2)
                enqueueSnackbar(
                  `The exit date you entered occurs before the enter date.`,
                  { variant: "error", tag: "ErrorInExitDate" }
                );
              if (datecomp == 1)
                enqueueSnackbar(
                  `The exit time you entered occurs before the enter time.`,
                  { variant: "error", tag: "ErrorInExitTime" }
                );
            }
            options.exit = newValue.exit;
            options.rows = 1;
            options.interval = Math.floor(
              moment
                .duration(
                  moment(options.exit).diff(
                    moment(options.enter).format(InternalFormat)
                  )
                )
                .asMinutes()
            );
            onChange(preview);
          }}
          onChange={val => {
            if (val == null) return;
            if (val.format(InternalFormat) == "Invalid date") return;
            newValue.exit = moment(val).format(InternalFormat);
          }}
          value={options.exit}
          inputVariant="outlined"
          margin="normal"
          label="Exit Date / Time"
          minDate={options.enter}
          format={DisplayFormat}
          autoOk
          mask={DisplayMask}
          variant="inline"
        />
      </MuiPickersUtilsProvider>
      {preview.overlapWarning != null && (
        <div className={classes.overlapWarning}>
          Warning: Rates &quot;<b>{preview.overlapWarning.rateOne}</b>&quot; and
          &quot;<b>{preview.overlapWarning.rateTwo}</b>&quot; have identical{" "}
          <i>Start Time</i>. If more than one rate is set to start at the same
          exact time, then one will be selected nondeterministically and others
          will be ignored.
        </div>
      )}
      <div className={classes.ratePreviewSection}>{renderedPreview}</div>
    </Card>
  );
};

RateAppPreview.defaultProps = {
  preview: {},
  onChange: () => {}
};

RateAppPreview.propTypes = {
  preview: PropTypes.shape({
    rateReceipts: PropTypes.arrayOf(PropTypes.object)
  }),
  onChange: PropTypes.func,
  options: PropTypes.shape({
    rows: PropTypes.number,
    interval: PropTypes.number,
    enter: PropTypes.string,
    exit: PropTypes.string
  })
};

export default RateAppPreview;
