import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import RichTextEditor from "../MUIRichTextEditor";

const RateDisplayPreview = ({ content, onChange, disabled }) => {
  const classes = useStyles();

  return (
    <div className={classes.imgContainer}>
      <RichTextEditor
        disabled={disabled}
        content={content}
        editorLabel="Enter Rates"
        onChange={onChange}
      />
    </div>
  );
};

RateDisplayPreview.defaultProps = {
  onChange: () => {},
  content: "",
  disabled: false,
};

RateDisplayPreview.propTypes = {
  content: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default RateDisplayPreview;
