import React, { useState } from "react";
import RateWizard_Absolute from "./RateWizard.Absolute";
import RateWizard_Relative from "./RateWizard.Relative";
import Button from "@material-ui/core/Button";
import styles from "../styles";
import clsx from "clsx";

const RateWizard = ({ onAdd, disabled }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [rateType, setRateType] = useState(null);
  const [newRateApplication, setNewRateApplication] = useState({});

  function updateRateApplication(rateApplication) {
    setNewRateApplication(rateApplication);
  }

  function addRateApplication() {
    onAdd(newRateApplication);
    setExpanded(false);
    setRateType(null);
  }

  function unsetRateType() {
    setRateType(null);
  }

  function getChooseRateWizard() {
    return (
      <div>
        <div>Select Rate Start Type</div>
        {RateWizardTypes.map((o, i) => (
          <Button
            key={i}
            value={i}
            onClick={() => setRateType(i)}
            className={clsx(`${o.name}_button`, style.RateWizardTypeSelectButton)}
          >
            {o.name}
          </Button>
        ))}
        <Button disabled={disabled} onClick={() => setExpanded(false)} className={clsx(`cancelBtn`)}>
          Cancel
        </Button>
      </div>
    );
  }

  function getRateWizard() {
    switch (rateType) {
      case null:
        return getChooseRateWizard();
      default:
        return React.createElement(
          "div",
          null,
          React.createElement(RateWizardTypes[rateType].class, {
            onChange: updateRateApplication,
          }),
          <div>
            <Button disabled={disabled} onClick={addRateApplication} className={clsx("rateWizardAddBtn")}>
              Add
            </Button>
            <Button disabled={disabled} onClick={unsetRateType} className={clsx("rateWizardCancelBtn")}>
              Cancel
            </Button>
          </div>
        );
    }
  }

  function getRateAddButton() {
    return (
      <Button
        disabled={disabled}
        className={clsx("addRateComponentBtn")}
        onClick={() => {
          setExpanded(!isExpanded);
        }}
      >
        add rate component
      </Button>
    );
  }

  const style = styles();

  return isExpanded ? getRateWizard() : getRateAddButton();
};

RateWizard.defaultProps = {
  onAdd: () => {},
  disabled: false,
};

export default RateWizard;

const RateWizardTypes = [
  { name: "Absolute Start Time", class: RateWizard_Absolute },
  { name: "Relative Start Time", class: RateWizard_Relative },
];
