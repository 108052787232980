import React, {useState, useEffect} from "react";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
  Tooltip
} from "@material-ui/core";
import clsx from "clsx";
import {useStyles} from "./styles";
import TablePaginationAction from "../../TablePaginationActions";
import DeleteDisabledToolTip from "./DeleteDisabledToolTip";

const RateListView = ({
                        rateArray,
                        toggleEditMode,
                        DeleteRatePermission,
                        EditRatePermission,
                        AddRatePermission,
                        deleteRate,
                      }) => {
  const [rateData, setRateData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (rateArray === undefined || rateArray == null) {
      return
    }
    const newRatesArray = rateArray.map((row) => {
      return ({
        name: row.name,
        id: row.rateID,
        description: row.description === "" ? " " : row.description,
        assignedOffers: row.assignedOffers,
        assignedEntities: row.assignedEntities,
        deleteDisabled: row.assignedEntities?.length > 0 || row.assignedOffers?.length > 0
      });
    }).sort((x, y) => x.name.localeCompare(y.name));
    setRateData(newRatesArray);
  }, [rateArray]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  return (<>
    <Grid container justify="center">
      <Grid item xs={12} md={12} lg={8}>
        <Paper style={{width: "100%"}} elevation={0}>
          <Grid container className={clsx(`Rates-panel`, classes.cardHeader)}>
            <Grid item xs={8} className={classes.headerTitle}>
              <Typography className={clsx("title")}>
                Rates
              </Typography>
            </Grid>

            {!!AddRatePermission &&
              <Grid item xs={4} className={classes.headerAddButton}>
                <Button
                  className={clsx("add-button")}
                  variant="contained"
                  color="primary"
                  onClick={() => toggleEditMode(true, null, true)}
                >
                  Add Rate
                </Button>
              </Grid>
            }
          </Grid>
          <Grid container className={clsx("table-container", classes.tableContainer)}>
            <Grid item xs={12} className={clsx("pagination")}>
              <TablePagination
                component="div"
                rowsPerPageOptions={[1, 3, 15, 25, 50]}
                className={clsx("table-pagination")}
                count={rateData?.length}
                rowsPerPage={rowsPerPage}
                page={page ?? 0}
                onChangePage={(event, newPage) => {
                  setPage(newPage);
                }}
                onChangeRowsPerPage={event => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
                ActionsComponent={TablePaginationAction}
              />
            </Grid>
            <Table className={clsx("table")}>
              <TableHead>
                <TableRow>
                  <TableCell align={"left"} padding={"default"} sortDirection={false}>
                    NAME
                  </TableCell>
                  <TableCell align={"left"} padding={"default"} sortDirection={false}>
                    DESCRIPTION
                  </TableCell>
                  <TableCell align={"left"} padding={"default"} sortDirection={false}>
                  </TableCell>
                </TableRow>
              </TableHead>
              {rateData.length <= 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography
                        className={clsx("no-data-message", classes.centerContentColumn, classes.noDataMessage)}>
                        There is no data to display.
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody data-testid="table-body">
                  {(rowsPerPage > 0
                    ? rateData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rateData).map(row => {
                    return (<>
                      <TableRow key={row?.id ?? 0} className={clsx(`${row.name}-table-row`,)}>
                        <TableCell data-value={row.name} className={clsx(`name-table-cell`)}>
                          <Typography className={clsx(`name-text`)}>
                            {row.name}
                          </Typography>
                        </TableCell>
                        <TableCell data-value={row.description} className={clsx(`description-table-cell`)}>
                          <Typography className={clsx(`description-text`)}>
                            {row.description}
                          </Typography>
                        </TableCell>
                        <TableCell className={clsx(`buttons-table-cell`)} align="right">
                          {!!EditRatePermission && (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => toggleEditMode(true, row.id)}
                              className={clsx(`edit-button`)}
                            >
                              Edit
                            </Button>
                          )}
                          {!!DeleteRatePermission && (
                            <>
                              {row.deleteDisabled ? (
                                <Tooltip
                                  title={<DeleteDisabledToolTip rate={row}></DeleteDisabledToolTip>}
                                  aria-label="delete"
                                  placement="left"
                                  arrow>
                                  <span>
                                      <Button
                                        disabled={true}
                                        variant="outlined"
                                        color="secondary"
                                        className={clsx(`delete-button`, classes.tableButtons)}
                                        onClick={() => deleteRate(row.id)}>
                                        Delete
                                      </Button>
                                  </span>
                                </Tooltip>
                              ) : (
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  className={clsx(`delete-button`, classes.tableButtons)}
                                  onClick={() => deleteRate(row.id)}>
                                  Delete
                                </Button>
                              )}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    </>);
                  })}
                </TableBody>
              )}
            </Table>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </>);
};

export default RateListView;
