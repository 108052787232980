import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
/* put styling for an individual textField here. Class name is it's label value and "_textField" */
  PrepaidEntryGraceTime_textField: {
    minWidth: "200px"
  },
  PrepaidEntryUpTo_textField: {
    minWidth: "200px"
  }
}));
