import { makeStyles } from "@material-ui/core/styles";

export const useTaxStyles = makeStyles(theme => ({
  taxContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%"
  },
  taxItem: {
    fontSize: "inherit"
  }
}));
