import React, {useState, useEffect} from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
} from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles";
import { useSelector, shallowEqual} from "react-redux";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import apiClient from "../../auth/apiClient";
import ReversingLaneService from "../../services/ReversingLaneService";
import Title from "../Title";
import ReversingLanesList from "./ReversingLanesList";
import {
  REVERSE_LANE_MODE
} from "../../constants";
import _ from "lodash";

const reversingLaneService = new ReversingLaneService(apiClient);

const ReversingLanesDrawer = ({onClose}) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const [reversingLanes, setReversingLanes] = useState([]);
  const facilityList = useSelector(
    (state) => {
      return _.orderBy(state.entityScope?.available, [ "name", "asc" ]);
    }, shallowEqual) ?? [];

  useEffect(() => {
    if (scopeAwareFacilityID && scopeAwareFacilityID != null) {
      getReversingLanes();
    }
  }, [scopeAwareFacilityID]);

  const getReversingLanes = async () => {
    try {
      var result = await reversingLaneService.getReversingLanes(scopeAwareFacilityID);
      setReversingLanes(result.data?? []);
    } catch (err){
      enqueueSnackbar("Unable to retrieve reversing lanes for this entity", {
        variant: "error",
        tag: "FailedToFindReversingLanesForEntity",
      });
    }
  };

  const handleClicked = async (laneID, reverseMode, entityid) => {
    try {
      await reversingLaneService.requestSwitchingLane({
        entityId: entityid,
        lanePairID : laneID,
        mode : Object.entries(REVERSE_LANE_MODE).find((element) => element[1] == reverseMode)[0],

      });
    } catch (err) {
      enqueueSnackbar("Unable to switch the reversing lane for this entity", {
        variant: "error",
        tag: "FailedToSwitchReversingLaneForEntity",
      });
    }
  };

  const buildMenu = () => {
    if (_.isNil(reversingLanes)) {
      return [];
    }

    const addFacilityDivider = facilityList && facilityList.length > 1;
    let followUpFacility = false;
    const output = [];
    for (let facility of facilityList) {
      let revLanes = reversingLanes.filter(x => x.entityID == facility.id);
      if (!revLanes || revLanes.length <= 0) {
        continue;
      }

      if (addFacilityDivider) {
        output.push(
          <Grid item
            key={`${facility.id}_subheader`}
            className={clsx(
              classes.facilityDivider,
              followUpFacility ? classes.facilityDividerSpacing : ""
            )}
            // clicking subheader initiates a select action
            // https://github.com/mui/material-ui/issues/18200
            // TODO: this might be messing with accessability/keyboard navigation FYI
            onClickCapture={(e) => e.stopPropagation()}
          >
            {facility.name}
          </Grid>
        );
        followUpFacility = true;
      }

      revLanes
          .sort((a, b) => a.laneName.localeCompare(b.laneName))
          .map((row) => {
           output.push(
              <ReversingLanesList
                key={row.laneID}
                lane={row}
                onClicked={handleClicked}
              />
            )
          }
        )
    }
    return output;
  };

return (
  <div className={classes.reversingLanesDrawer}>
    {reversingLanes.length != 0 && 
      <Box className={classes.reversingLanesBox}>
        <Grid container className={clsx([classes.headerContainerSpacing])}>
          <Grid item xs={12} sm={12}>
            <Title>Reversing Lane Status</Title>
          </Grid>
        </Grid>
        <Divider className={classes.reversingLaneDivider} />
        <Grid container className={clsx([classes.listTitle])}>
          <Grid item xs={4} lg={4} className={clsx([classes.reversingLanesText, "lane-name"])}>Lane Name</Grid>
          <Grid item xs={4} lg={3} className={clsx([classes.reversingLanesText, "current-mode"])}>Current Mode</Grid>
          <Grid item xs={4} lg={5}></Grid>
        </Grid>
        {buildMenu()}
      </Box>
    }
    <Box className={clsx([classes.drawerPaper])}>
      <Divider className={classes.reversingLaneDivider} />
      <Button
        className={clsx("peripheral-cancel", classes.cancelBtn)}
        variant="contained"
        data-id="closeBtn"
        onClick={onClose}
      >
        Close
      </Button>
    </Box>
  </div>
  )
};

export default ReversingLanesDrawer;