import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Checkbox from "../Inputs/Checkbox";
import DurationEntry, { getVerboseDisplayValue } from "../Inputs/DurationEntry";

export const getReadablePricingTextAdvanced = function(records) {
  var rv = [];
  for(var i=records.length;i-->0;) {
    rv.push(<div key="pcs">{`$${records[i].price} per ${getVerboseDisplayValue(records[i].duration*records[i].durationMultiplier)} ${records[i].maxLimit?` for ${getVerboseDisplayValue(records[i].maxLimit*records[i].duration*records[i].durationMultiplier)}`:""}`}</div>)
  }
  return rv;
}

const PricingStructureAdvanced = ({ record, onChange }) => {
  const handleChange = (name, value) => {
    record[name] = value;
    onChange();
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    if (e.target.type === "number") value = parseFloat(e.target.value, 10);
    record[e.target.name] = value;
    onChange();
  };

  return (
    <div>
      <TextField
        label="Price per Duration Unit"
        type="number"
        name="price"
        value={parseFloat(record.price)}
        onChange={handleInputChange}
        title=""
      />
      <DurationEntry
        label="Duration"
        name="duration"
        value={record.duration * record.durationMultiplier}
        onChange={handleChange}
        title="something"
      />
      <TextField
        label="Line Item Label"
        type="text"
        name="durationName"
        value={record.durationName}
        onChange={handleInputChange}
        title=""
      />
      <TextField
        label="Maximum Repeat"
        type="number"
        name="maxLimit"
        value={record.maxLimit}
        onChange={handleInputChange}
        title=""
      />
      <Checkbox
        label="Carry Overflow"
        name="carryOverflow"
        value={record.carryOverflow}
        onChange={handleChange}
        title="When checked, the calculation will revert to using smaller Duration Units if any are available."
      />
    </div>
  );
};

PricingStructureAdvanced.displayName = "PricingStructureAdvanced";

PricingStructureAdvanced.defaultProps = {
  onChange: () => {},
};

PricingStructureAdvanced.propTypes = {
  record: PropTypes.shape({
    key: PropTypes.string,
    price: PropTypes.number,
    duration: PropTypes.number,
    durationMultiplier: PropTypes.number,
    durationName: PropTypes.string,
    maxLimit: PropTypes.number,
    carryOverflow: PropTypes.bool,
  }),
};

export default PricingStructureAdvanced;
