import { makeStyles } from "@material-ui/core/styles";
import background from "../../assets/RateDisplayPreview.svg";

export const useStyles = makeStyles((theme) => ({
  imgContainer: {
    height: "600px",
    padding: theme.spacing(2),
    backgroundImage: `url(${background})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "400px",
  }
}));
