import React from "react";
import {Grid,Button} from "@material-ui/core";
import clsx from "clsx";
import {useStyles} from "./styles";
import { useSelector,shallowEqual} from "react-redux";
import { FindEntity } from "../../state/slices/entities";
import {
  REVERSE_LANE_MODE
} from "../../constants";

const ReversingLanesList= ({lane, onClicked}) =>{
  const classes = useStyles();

  const entryDeviceState = useSelector((state) => {
    const entity = FindEntity(state.entities?.EntityList ?? [], lane.laneDevices.find(x => x.mode == REVERSE_LANE_MODE.Entry)?.deviceID);    
    return {
      reversing: entity?.state?.ReversingLane?.LaneID == lane.laneID ? 
        entity?.state?.ReversingLane?.Reversing : false,
      reverseOffline: entity?.state?.ReversingLane?.LaneID == lane.laneID ?
        entity?.state?.ReversingLane?.ReverseOffline : true
    };
  }, shallowEqual);

  const exitDeviceState = useSelector((state) => {
    const entity = FindEntity(state.entities?.EntityList ?? [], lane.laneDevices.find(x => x.mode == REVERSE_LANE_MODE.Exit)?.deviceID);
    return {
      reversing: entity?.state?.ReversingLane?.LaneID == lane.laneID ? 
        entity?.state?.ReversingLane?.Reversing : false,
      reverseOffline: entity?.state?.ReversingLane?.LaneID == lane.laneID ? 
        entity?.state?.ReversingLane?.ReverseOffline : true
    };
  }, shallowEqual);

  const isReversing = (entry, exit) => {
    return entry.reversing == true || exit.reversing == true ? true : false;
  };
  const checkCurrentMode = (entry, exit) => {
    if (entry.reverseOffline == false && exit.reverseOffline == true) {
      return REVERSE_LANE_MODE.Entry
    } else if (entry.reverseOffline == true && exit.reverseOffline == false) {
      return REVERSE_LANE_MODE.Exit
    } else {
      return REVERSE_LANE_MODE.Unknown
    }
  };

  const showCurrentMode = (entry, exit) => {
    return isReversing(entry, exit) ? "Reversing"
    : Object.entries(REVERSE_LANE_MODE)?.find(element => element[1] == checkCurrentMode(entry, exit))[0];
  };

  const showSwitchMode = (entry, exit) => {
    return checkCurrentMode(entry, exit) == REVERSE_LANE_MODE.Entry ? "Switch to Exit" : "Switch to Entrance";
  };  

  const handleClicked = (switchMode) => {
    onClicked(lane.laneID, switchMode == "Switch to Entrance" ? REVERSE_LANE_MODE.Entry : REVERSE_LANE_MODE.Exit, lane.entityID);
  };

return (
  <Grid container className={clsx([classes.reversingLanesLine])}>
    <Grid item xs={4} lg={4} className={clsx([classes.reversingLanesLine])}>
    <p className={clsx([classes.reversingLanesText])}>
      {lane.laneName}
    </p>
    </Grid>
    <Grid item xs={4} lg={3} className={clsx([classes.reversingLanesLine])}>
    <p className={clsx([classes.reversingLanesText])}>
      {showCurrentMode(entryDeviceState, exitDeviceState)}
    </p>    
    </Grid>
    <Grid item xs={4} lg={5} className={clsx([classes.reversingLanesLine])}>
      <Button
          fullWidth
          className={clsx("mode-switch", classes.switchBtn)}
          color="primary"
          variant="contained"
          data-id="switchBtn"
          onClick={(e)=>handleClicked(e.target.textContent)}
          disabled={isReversing(entryDeviceState, exitDeviceState)}
      >
        {showSwitchMode(entryDeviceState, exitDeviceState)}
      </Button>
      {checkCurrentMode(entryDeviceState, exitDeviceState) == REVERSE_LANE_MODE.Unknown &&
      <Button
        fullWidth
        className={clsx("mode-switch", classes.switchBtn)}
        color="primary"
        variant="contained"
        data-id="switchBtn"
        onClick={handleClicked}
        disabled={isReversing(entryDeviceState, exitDeviceState)}
      >
        Switch to Exit
      </Button>}
    </Grid>                            
  </Grid>
  );
};
export default ReversingLanesList;