import React, { useState } from "react";
import PropTypes from "prop-types";
import CadenceSelector, {
  getReadableCadenceText,
  getTimeFromCronex,
} from "./Inputs/CadenceSelector";
import TextField from "@material-ui/core/TextField";
import Checkbox from "./Inputs/Checkbox";
import PricingStructure, {
  getReadablePricingText,
} from "./Pricing/PricingStructure";
import DurationEntry, {
  getVerboseDisplayValue,
  getValue,
} from "./Inputs/DurationEntry";
import styles from "./styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import clsx from "clsx";

export const getTimeFromTimeAndOffset = function(starttime, timeadd) {
  var startHour = parseInt(starttime.split(":")[0]);
  var startMinute = parseInt(starttime.split(":")[1]);
  var addHour = Math.floor(timeadd / 3600);
  var addMinute = Math.floor((timeadd % 3600) / 60);
  var finalHour = startHour + addHour;
  var finalMinute = startMinute + addMinute;
  finalHour += Math.floor(finalMinute / 60);
  finalMinute = finalMinute % 60;
  var days = Math.floor(finalHour / 24);
  finalHour -= days * 24;
  var result =
    finalHour + ":" + ((finalMinute + "").length == 2 ? "" : "0") + finalMinute;
  if (days) result += ` (${days} day${days == 1 ? "" : "s"} later)`;
  return result;
};

const RateApplication = React.memo(
  ({ rate, onChange, disabled }) => {
    const style = styles();
    const [isExpanded, setExpanded] = useState(false);
    const options = { showPriority: false };
    const enqueueSnackbar = useEnqueueSnackbar();

    const durationLabel = () => {
      if (rate.overlaidRate) return "Overlaid Rate Duration";
      if (rate.entryOnly) return "Entry Allowed Time";
    };

    const changeRateTitle = (event) => {
      var name = event.target.name;
      var value = event.target.value;
      for (var i = 0; i < rate.rateRecords.length; i++) {
        rate.rateRecords[i].rateTitle = value;
      }
      handleRateDataChange();
    };

    const handleRateDataChange = (name, value) => {
      if (value != null) rate[name] = value;
      onChange();
    };

    const handleTextInputChange = (e) => {
      handleRateDataChange(e.target.name, e.target.value);
    };

    const getReadableRateOptionsSummary = function(rate) {
      var rv = [];
      if (rate.overlaidRate) {
        rv.push(
          <div key="overlaidmsg">
            Overrides the price for the duration of{" "}
            {getVerboseDisplayValue(rate.entryDuration)} from each start of rate
            component.
          </div>
        );
      }
      if (rate.entryOnly && !rate.cadence.startsWith("relative")) {
        rv.push(
          <div key="entryonlymsg">
            Starts only if the parker entered the lot while the rate was the
            currently active rate. This rate is ignored otherwise.
          </div>
        );
        rv.push(
          <div key="entryonlymsg_sub1">
            Entry time must be after {getTimeFromCronex(rate.cadence)} and
            before{" "}
            {getTimeFromTimeAndOffset(
              getTimeFromCronex(rate.cadence),
              rate.entryDuration
            )}
          </div>
        );
        rv.push(
          <div key="entryonlymsg_sub2">
            Exit time must be after{" "}
            {getTimeFromTimeAndOffset(
              getTimeFromCronex(rate.cadence),
              rate.exitStart
            )}{" "}
            and before{" "}
            {getTimeFromTimeAndOffset(
              getTimeFromCronex(rate.cadence),
              rate.exitEnd
            )}
          </div>
        );
      }
      if (rate.entryOnly && rate.cadence.startsWith("relative")) {
        getVerboseDisplayValue(rate.exitStart) &&
          rv.push(
            <div key="entryonlymsg_sub1">
              Exit time must be no earlier than{" "}
              {getVerboseDisplayValue(rate.exitStart)} after the start of this
              rate.
            </div>
          );
        rv.push(
          <div key="entryonlymsg_sub2">
            Exit time must be no later than{" "}
            {getVerboseDisplayValue(rate.exitEnd) || "0 minutes"} after the
            start of this rate.
          </div>
        );
      }
      if (!rate.forcePrice) {
        rv.push(
          <div key="forcedmsg">
            Rate will only apply if using this rate results in a lower price.
          </div>
        );
      }
      return rv;
    };

    var readableCadenceText = (
      <div className={style.RateComponentHeaderSummarySection}>
        {getReadableCadenceText(rate.cadence)}
      </div>
    );
    var readableRateOptionsSummary = (
      <div className={style.RateComponentHeaderSummarySection}>
        {getReadableRateOptionsSummary(rate)}
      </div>
    );
    var readablePricingText = (
      <div className={style.RateComponentHeaderSummarySection}>
        {getReadablePricingText(rate.rateRecords)}
      </div>
    );
    var rateTitle = rate.rateRecords.length
      ? rate.rateRecords[0].rateTitle
      : rate.rateTitle;
    return (
      <div>
        {!disabled && (
          <IconButton
            aria-label="delete"
            onClick={() => setExpanded(!isExpanded)}
            className={clsx("EditIconButton", style.RateComponentEditButton)}
            data-testid="editIconButton"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )}
        <div style={{ display: isExpanded ? "none" : "block" }}>
          <div className={style.RateComponentHeader}>
            <div className={style.RateComponentHeaderTitle}>{rateTitle}</div>
            <div className={style.RateComponentHeaderSummary}>
              {readableCadenceText}
              {readableRateOptionsSummary}
              {readablePricingText}
            </div>
          </div>
        </div>
        <div style={{ display: isExpanded ? "block" : "none" }}>
          <div className={style.RateComponentSection}>
            <TextField
              label="Rate Component Label"
              className={clsx("rateComponentLabelTextField")}
              type="text"
              name="rateTitle"
              value={rate.rateTitle}
              onChange={changeRateTitle}
              title="The label to show for this rate component."
            />
          </div>

          <div className={style.RateComponentSection}>
            {readableCadenceText}
            <CadenceSelector
              name="cadence"
              cadence={rate.cadence}
              onChange={handleRateDataChange}
            />
          </div>
          {options.showPriority ? (
            <div className={style.RateComponentSection}>
              <TextField
                label="Priority"
                type="number"
                name="priority"
                className={clsx("priorityTextField")}
                value={rate.priority}
                onChange={handleTextInputChange}
                title=""
              />
            </div>
          ) : (
            ""
          )}
          <div className={style.RateComponentSection}>
            {readableRateOptionsSummary}
            <Checkbox
              label="Overlaid Rate"
              name="overlaidRate"
              value={rate.overlaidRate}
              className={clsx("overlaidRateCheckbox")}
              onChange={(name, value) => {
                handleRateDataChange(name, value);
                if (value == false && rate.entryOnly == false)
                  handleRateDataChange("forcePrice", true);
              }}
              title="When checked, this rate will override portion existing rates for the specified duration"
            />
            <Checkbox
              label="Entry Only"
              name="entryOnly"
              value={rate.entryOnly}
              className={clsx("entryOnlyCheckbox")}
              onChange={(name, value) => {
                handleRateDataChange(name, value);
                if (value == false && rate.overlaidRate == false)
                  handleRateDataChange("forcePrice", true);
              }}
              title="When checked, the rate only applies when entering lot during the effective time of this rate"
            />
            {rate.overlaidRate || rate.entryOnly ? (
              <Checkbox
                label="Apply Partially"
                name="applyPartially"
                className={clsx("applyPartiallyCheckbox")}
                value={rate.applyPartially}
                onChange={handleRateDataChange}
                title="When checked, rate can apply partially when if the entry and exit requirements have not been fully met."
              />
            ) : (
              ""
            )}
            {rate.overlaidRate || rate.entryOnly ? (
              <Checkbox
                label="Forced Rate"
                name="forcePrice"
                className={clsx("forcedRateCheckbox")}
                value={rate.forcePrice}
                onChange={handleRateDataChange}
                title="When checked, this rate always applies. When not checked, the rate is applied only if ends up being a discount."
              />
            ) : (
              ""
            )}
            {rate.concatenatedRate ? (
              <Checkbox
                label="Concatenated Rate"
                name="concatenatedRate"
                className={clsx("concatenatedRateCheckbox")}
                value={rate.concatenatedRate}
                onChange={handleRateDataChange}
                title="When checked, different instances of this rate will count as one instance"
              />
            ) : (
              ""
            )}
            {rate.overlaidRate || rate.entryOnly ? (
              <DurationEntry
                label={durationLabel()}
                name="entryDuration"
                value={rate.entryDuration}
                onChange={handleRateDataChange}
                title="The duration of the overlaid rate"
              />
            ) : (
              ""
            )}
            {rate.entryOnly ? (
              <DurationEntry
                label="Exit After"
                name="exitStart"
                value={rate.exitStart}
                onChange={handleRateDataChange}
                title=""
              />
            ) : (
              ""
            )}
            {rate.entryOnly ? (
              <DurationEntry
                label="Exit Before"
                name="exitEnd"
                value={rate.exitEnd}
                onChange={handleRateDataChange}
              />
            ) : (
              ""
            )}
          </div>
          <div className={style.RateComponentSection}>
            Pricing Structure During Rate Component
            <PricingStructure
              records={rate.rateRecords}
              onChange={handleRateDataChange}
            />
          </div>
          <div className={style.RateComponentSection}>
            <DurationEntry
              label="Grace Time"
              name="graceTime"
              value={rate.graceTime}
              onChange={handleRateDataChange}
            />
            <DurationEntry
              label="Lag Time"
              name="lagTime"
              value={rate.lagTime}
              onChange={handleRateDataChange}
            />
            <DurationEntry
              label="Comp Time"
              name="compTime"
              value={rate.compTime}
              onChange={handleRateDataChange}
            />
          </div>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.rateString == nextProps.rateString;
  }
);

RateApplication.displayName = "RateApplication";

RateApplication.defaultProps = {
  onChange: () => {},
  disabled: false,
};

RateApplication.propTypes = {
  rate: PropTypes.shape({
    key: PropTypes.string,
    rateRecords: PropTypes.array,
    overlaidRate: PropTypes.bool,
    entryOnly: PropTypes.bool,
    cadence: PropTypes.string,
    priority: PropTypes.number,
    applyPartially: PropTypes.bool,
    forcePrice: PropTypes.bool,
    concatenatedRate: PropTypes.bool,
    entryDuration: PropTypes.number,
    exitStart: PropTypes.number,
    exitEnd: PropTypes.number,
    graceTime: PropTypes.number,
    lagTime: PropTypes.number,
    compTime: PropTypes.number,
  }),
  disabled: PropTypes.bool,
};

export default RateApplication;
