import React, { useState, useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import DurationEntry from "../Inputs/DurationEntry";
import TextField from "@material-ui/core/TextField";
import CadenceSelector, { getReadableCadenceText } from "../Inputs/CadenceSelector";
import styles from "../styles";

const initialState = `{
  "start":"relative",
  "relativeDuration":604800,
  "price":0,
  "rateTitle":"Rate Title"
}`;

const RateWizardMaxRate = ({ onChange }) => {
  const [state, setState] = useState(JSON.parse(initialState));

  function changeState(name,value) {
    state[name] = value;
    setState({...state});
    updateRateApplication(state);
  }

  function change(event) {
    changeState(event.target.name,event.target.value);
  }

  function updateInitial() {
    updateRateApplication();
  }

  function updateRateApplication(st) {
    if(!st) st=state;
    var rateApplicationString = `{
      "cadence": "${`relative:0 ${st.relativeDuration}`}",
      "priority": 1,
      "entryOnly": false,
      "entryDuration": ${st.relativeDuration},
      "exitStart": 0,
      "exitEnd": 0,
      "applyPartially": false,
      "forcePrice": true,
      "overlaidRate": false,
      "concatenatedRate": false,
      "rateRecords": [{
        "rateTitle": "${st.rateTitle}",
        "type": 1,
        "price": ${st.price},
        "currencyAbbreviation": "USD",
        "duration": 0,
        "durationMultiplier": 1,
        "durationName": "second",
        "maxLimit": 0,
        "carryOverflow": false
      }]
    }`;
    onChange(JSON.parse(rateApplicationString));
    return st;
  }


  const style = styles();
  const returnValue = [];
  returnValue.push(
    <div className={style.RateComponentSection} key="rcs1">
      <div className={style.RateComponentHeaderTitle}>Rate Component with a Relative Start Time.</div>
    </div>
  );

  useEffect(updateInitial,[]);

  return returnValue;
};

RateWizardMaxRate.defaultProps = {
  onChange:()=>{}
};

export default RateWizardMaxRate;

