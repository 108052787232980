import React from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiCheckbox from "@material-ui/core/Checkbox";

const Checkbox = ({ label, title, name, value, onChange, className }) => {
  const handleCheckboxChange = (e, val) => {
    onChange(name, val);
  };

  return (
    <div>
      <FormControlLabel
        control={<MuiCheckbox color="primary" checked={value} className={className} />}
        label={label}
        title={title}
        labelPlacement="end"
        onChange={handleCheckboxChange}
      />
    </div>
  );
};

Checkbox.defaultProps = {
  onChange: () => {},
};

Checkbox.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
